import React from 'react';
import {HashRouter, Routes, Route} from 'react-router-dom';
import BlogPostSummary from './BlogPostSummary';
import BlogPostDetail from './BlogPostDetail';
import animatedLogo from './header-black.gif';
import Header from './Header.js';
import LandingPage from './LandingPage.js';
import ContactForm from "./ContactForm";
import GameList from "./GameList";

function App() {
    return (<HashRouter>
        <div
            className="flex flex-col min-h-screen"> {/* Make sure the container is a flex column and takes up at least the viewport height */}

            {/* Content wrapper */}
            <div className="flex-grow bg-white">
                <div className="bg-white p-4">
                    <img src={animatedLogo} alt="animatedLogo" className="mx-auto"/>
                </div>

                {/* Navigation Header */}
                <Header/>

                {/* Routes for Blog Post Summary and Detail */}
                <Routes>
                    <Route path="/" element={<LandingPage/>}/>

                    {/*<Route path="/blog" element={<main className="flex-grow">*/}
                    {/*    <div className="max-w-4xl mx-auto py-8 px-4">*/}
                    {/*        <div className="space-y-4">*/}
                    {/*            <BlogPostSummary id="1" title="Blog Post Title 1" publishedOn="July 1, 2021"*/}
                    {/*                             excerpt="This is a short excerpt from the blog post to give readers a preview of the content..."/>*/}
                    {/*            <BlogPostSummary id="2" title="Blog Post Title 2" publishedOn="August 15, 2021"*/}
                    {/*                             excerpt="This is a short excerpt from the blog post to give readers a preview of the content..."/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</main>}*/}
                    {/*/>*/}

                    {/*<Route path="/about" element={<main className="flex-grow">*/}
                    {/*    <div className="max-w-4xl mx-auto py-8 px-4">*/}
                    {/*        <div className="space-y-4">*/}
                    {/*            <div className="flex justify-center">*/}
                    {/*                <div className="w-1/2">*/}
                    {/*                    <p className="text-2xl font-bold mb-4">About Us</p>*/}
                    {/*                    <p>ACME Corp is a software development company that specializes in*/}
                    {/*                        creating games for various platforms in multiple game engines.*/}
                    {/*                        We are passionate about creating fun and engaging games for players*/}
                    {/*                        of all ages.</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="space-y-4">*/}
                    {/*            <div className="flex justify-center">*/}
                    {/*                <div className="w-1/2">*/}
                    {/*                    <GameList/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</main>}*/}
                    {/*/>*/}
                    <Route path="/blog/post/:id" element={<BlogPostDetail/>}/>
                    <Route path="/contact" element={<ContactForm/>}/>
                </Routes>
            </div>

            {/* Footer */}
            <footer
                className="bg-black text-gray-400 mt-auto"> {/* mt-auto will automatically margin top as much as possible pushing the footer to the bottom */}
                <div className="max-w-6xl mx-auto px-4 py-1 text-sm text-center">
                    <p>Copyright &copy;coffeepoweredgames.com 2018.-2025.</p>
                    <p>&quot;The Logo&copy;&quot; and the words &quot;Coffee Powered Games&copy;&quot; are a registered
                        trademark for software development.</p>
                </div>
            </footer>
        </div>
    </HashRouter>);
}

export default App;
